export class RefugeImage {
    link: string;
    alt: string = "";
    width: number;
    height: number;

    constructor(link: string, alt: string, width?: number, height?: number) {
        this.link = link;
        this.alt = alt;
        this.width = width ?? 400;
        this.height = height ?? 400;
    }
}
